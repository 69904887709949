import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// mui import
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// project import
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { getLocalDate } from "../../utilities/helpers";
import { setInspection } from "../../state";

import assignmentApi from "../../apis/assignmentApi";
import StarRateIcon from '@mui/icons-material/StarRate';
import { red, yellow, green, blue } from '@mui/material/colors';


const Assignments = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  const columns = [
    {
      field: "subject",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "firm_name",
      headerName: "Auditing Firm",
      flex: 1,
    },
    {
      field: "firm_to_be_audited_name",
      headerName: "Firm To Be Audited",
      flex: 1,
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
    },
    {
      field: "assignment_status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "target_complete_date",
      headerName: "Target Date",
      flex: 1,
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            {row.rating <= 3 && <StarRateIcon sx={{ color: red[700]}} />}
            {row.rating > 3 && row.rating <= 6 && <StarRateIcon sx={{ color: yellow[700]}} />}
            {row.rating > 6 && row.rating <= 9 && <StarRateIcon sx={{ color: green[700]}} />}
            {row.rating === 10 && <StarRateIcon sx={{ color: blue[700] }} />}
          </Box>
        );
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Inspect",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Tooltip title="Inspection">
              <IconButton color="warning" onClick={() => handleInspection(row)} variant="">
                  <img src="../../assets/ieqiplogo.png" alt="test" width="30px" height="30px" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "assignment_uid",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Tooltip title="Edit">
              <IconButton
                color="success"
                aria-label="Edit"
                component={Link}
                to={`/assignments/${row.assignment_uid}/edit`}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={() => handleDelete(row)} variant="">
                  <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const requestHeader = {
          headers: {
            "x-eqip-tenantid": tenantId,
            "Authorization": idToken
          },
        };

        // show assignments are assgined to or created by logined user
        const responseAssignments = await assignmentApi.get(
          `/assignments/assignto/${loggedInUser.email}`,
          requestHeader
        );

        const data = responseAssignments.data.body.map((item) => {
          item.target_complete_date = getLocalDate(item.target_complete_date);
          item.rating = calculateLowestRating(item);
          return item;
        });
        setAssignments(data);
        setIsLoading(false);
      } catch (err) {
        // Handle Error Here
        console.error(err);
        setIsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [loggedInUser.email, tenantId]);

  const handleDelete = async (assignment) => {
    try {
      setIsLoading(true);
      await assignmentApi.put('/assignments/disableAssignment', assignment);
      setAssignments(assignments.filter(item => item.assignment_uid !== assignment.assignment_uid));
      setIsLoading(false);
    } catch (err) {
      // Handle Error Here
      console.error(err);
      setIsLoading(false);
    }
  }

  const handleInspection = async (assignment) => {
    try {
      dispatch(
        setInspection({
          isShowInspection: true,
          inspectionUid: assignment.inspection_uid,
          assignmentUid: assignment.assignment_uid
        })
      );
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  const calculateLowestRating = (row) => {
    let lowestRating;
    if (Array.isArray(row.protocol_quality) && row.protocol_quality.length > 0) {
      // Filter out non-numeric values and convert to numbers
      const numericRatings = row.protocol_quality
        .map(quality => quality.detail.rating || 999)
        .map(Number);

      if (numericRatings.length > 0) {
        lowestRating = Math.min(...numericRatings);
      } else {
        console.log('No valid numeric ratings found');
      }
    } else {
      console.log('protocol_quality is not a valid array or is empty');
    }
    return lowestRating;
  }

  return (
    <Box m="20px">
      <Header title="ASSIGNMENTS" subtitle="Managing the Assignments" />
      <Box display="flex" justifyContent="end">
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          component={Link}
          to="/assignments/create"
        >
          Create Assignment
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[900],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[500]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection={false} rows={assignments} columns={columns} loading={isLoading} />
      </Box>
    </Box>
  );
};

export default Assignments;
